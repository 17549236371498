import React, { useState, useEffect } from 'react'
import {Alert, Button, Menu, Modal, Typography, Image, Row, Col} from "antd";
import {useHistory} from "react-router-dom";
import {permissionsExist} from "../lib/utils/utils";
import {useSelector} from "react-redux";
import KModal from "./common/elements/KModal/KModal";
import KButton from "./common/elements/KButton/KButton";
import noService from '../image/no_service.png'

const { Title } = Typography;

const PendingPaymentAlert =({show=false})=>{
    const history = useHistory();
    const [showModal, setShowModal] = useState(true)
    const permissions = useSelector(state => state.permissions);
    const securityStore = useSelector(state => state.security);


    // Función para calcular los días transcurridos del mes actual
    const calcularDiasTranscurridos = () => {
        const hoy = new Date();
        return hoy.getDate();
    }

    // Función para determinar el tipo de alerta basado en pagos atrasados
    const shouldShowAlert = () => {
        const latePayments = securityStore?.latePayments?.late_payments || 0;
        const daysLate = calcularDiasTranscurridos();

        // Lógica para determinar el tipo de alerta
        if (latePayments >= 2) return 'error';
        if (latePayments === 1) {
            if (daysLate >= 21) return 'error';
            if (daysLate >= 8) return 'warning';
        }
        return null;
    }

    const alertType = shouldShowAlert();

    return (
        <>
            {
                // Renderiza la alerta si hay un tipo de alerta
                alertType && (
                    <Alert 
                        showIcon={true} 
                        action={
                            permissionsExist(permissions.permissionsList,"view_paymentorder") &&
                            <Button danger onClick={()=> history.push('/membership')} size="small" type="text">
                                Ver facturas
                            </Button>
                        } 
                        message={`Su cuenta presenta ${securityStore?.latePayments?.late_payments} facturas pendientes de pago, contacte a su asesor Kikert para evitar la suspensión del servicio.`} 
                        type={alertType} 
                    />
                )
            }
            {
                // Muestra un modal si la cuenta está inactiva
                securityStore?.latePayments?.account_is_active===false ?
                    <KModal
                        visible={showModal}
                        width={600}
                        closable={false}
                        title={'Servicio suspendido'}
                    >
                        <Row align={'middle'}>

                            <Col span={24}>
                                <p style={{textAlign:'center'}}>
                                    <Image src={noService} preview={false} width={150}/>
                                    <Title level={4} style={{textAlign:'center',marginTop:10}}>Esta cuenta presenta {securityStore?.latePayments?.late_payments} facturas pendientes de pago, porfavor contacte a su asesor Kikert para obtener más información.</Title>
                                    <KButton text={'Ir a membresía'} onClick={()=>{
                                        history.push('/membership')
                                    }}/>
                                    
                                </p>
                            </Col>
                        </Row>
                    </KModal>:null
            }
        </>
    )
}

export default PendingPaymentAlert;