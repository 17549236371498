import React, { useEffect, useState } from 'react';
import { Button, Space, Row, Col, Alert } from 'antd';
import { Global, css } from '@emotion/react';
import { useHistory, useParams } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { FileTextOutlined } from '@ant-design/icons';
import { tenantSelected } from '../../lib/utils/axiosApi';
import SectionTitle from "../common/elements/SectionTitle/SectionTitle";
import useSettingsKikert from "../../hooks/useSettingsKikert";
import useCustomTags from "../../hooks/useCustomTags";
import {permissionsExist} from "../../lib/utils/utils";

import inicio_icon from '../../image/detail/inicio_icon.svg';
import entrada_icon from '../../image/detail/entrada_icon.svg';
import valuacion_icon from '../../image/detail/valuacion_icon.svg';
import refacciones_icon from '../../image/detail/refacciones_icon.svg';
import reparacion_icon from '../../image/detail/reparacion_icon.svg';
import entregar_icon from '../../image/detail/entregar_icon.svg';
import chatIcon from "../../image/chat_icon.svg";
import activityIcon from "../../image/detail/fellow-activity-svgrepo-com.svg";

const MenuServiceDetail = ({  intl, settings, menuSelected = 0, ...props }) => {
  let history = useHistory();
  let { id } = useParams();
  const { settingsKikert, getMetaValue } = useSettingsKikert();
  const { getCustomTag } = useCustomTags();
  const isTablet = useMediaQuery({ query: '(max-width: 1024px)' });
  const permissions = useSelector(state => state.permissions);

  return (
    <>
      <Global
        styles={css`
          .message_patio {
            background-color: #090731 !important;
            color: #ffff;
            border-radius: 6px;
            border:none;
          }
          .messages {
            width: 150px;
            text-align:center;
            padding: 6px 10px;
          } 
          .message_patio .ant-alert-message,
          .message_valuar .ant-alert-message {
            color: #ffff;
          }
          .message_valuar {
            background-color: #eb726c !important;
            color: #ffff;
            border-radius: 6px;
            border:none;
          }
        `}
      />
      <Row justify="space-between">
        <Col>
        </Col>
        <Col style={isTablet ? { overflow: 'scroll', maxHeight: '100vh' } : { }}>
          <Space>
            <Button
              style={menuSelected === 0 ? { ...style.itemmenu, ...style.itemSelected } : style.itemmenu}
              type="text"
              onClick={() => history.push(`/service-detail/${id}`)}
              icon={<img style={{ width: 15, marginRight: 5 }} src={inicio_icon} />}
            >
              <FormattedMessage id="servicesDetails.home" />
            </Button>
            {settings && !settings.isHideEntrySection && (
              <Button
                style={menuSelected === 1 ? { ...style.itemmenu, ...style.itemSelected } : style.itemmenu}
                type="text"
                onClick={() => history.push(`/workshop-entrance/${id}`)}
                icon={<img style={{ width: 20, marginRight: 5 }} src={entrada_icon} />}
              >
                <FormattedMessage id="servicesDetails.entrance" />
              </Button>
            )}
            
            {tenantSelected === 'jacmexico' && (
              <Button
                style={menuSelected === 1 ? { ...style.itemmenu, ...style.itemSelected } : style.itemmenu}
                type="text"
                onClick={() => history.push(`/workshop-entrance-jac/${id}`)}
                icon={<img style={{ width: 20, marginRight: 5 }} src={entrada_icon} />}
              >
                <FormattedMessage id="servicesDetails.entrance" />
              </Button>
            )}

            {settings && settings.valuationIsActive && /*permissionsExist(permissions.permissionsList, "view_valuation") &&*/ (
              <Button
                style={menuSelected === 2 ? { ...style.itemmenu, ...style.itemSelected } : style.itemmenu}
                type="text"
                onClick={() => history.push(`/service-valuation/${id}`)}
                icon={<img style={{ width: 10, marginRight: 5 }} src={valuacion_icon} />}
              >
                <FormattedMessage id="servicesDetails.valuation" />
              </Button>
            )}

            {settings && settings.valuationIsActive && tenantSelected !== 'jacmexico' && (
              <Button
                style={menuSelected === 3 ? { ...style.itemmenu, ...style.itemSelected } : style.itemmenu}
                type="text"
                onClick={() => history.push(`/service-parts/${id}`)}
                icon={<img style={{ width: 15, marginRight: 5 }} src={refacciones_icon} />}
              >
                <FormattedMessage id="servicesDetails.spareParts" />
              </Button>
            )}
            <Button
              style={menuSelected === 4 ? { ...style.itemmenu, ...style.itemSelected } : style.itemmenu}
              type="text"
              onClick={() => history.push(`/service-repair/${id}`)}
              icon={<img style={{ width: 15, marginRight: 5 }} src={reparacion_icon} />}
            >
              {getCustomTag('repair')}
            </Button>
            {
              true && (
                <Button
                  style={menuSelected === 8 ? { ...style.itemmenu, ...style.itemSelected } : style.itemmenu}
                  type="text"
                  onClick={() => history.push(`/activities/${id}`)}
                  icon={<img style={{ width: 15, marginRight: 5 }} src={activityIcon} />}
                >
                  <FormattedMessage id="services.activities" />
                </Button>
              )
            }
            {
              getMetaValue('CHAT_SECTION_IS_ACTIVE','1') === '1' && (
                  <Button
                    style={menuSelected === 5 ? { ...style.itemmenu, ...style.itemSelected } : style.itemmenu}
                    type="text"
                    onClick={() => history.push(`/order-chat/${id}`)}
                    icon={<img style={{ width: 15, marginRight: 5 }} src={chatIcon} />}
                  >
                    <FormattedMessage id="chat.chatCustomer"/>
                  </Button>
              )
            }
            
            {getMetaValue('INVOICE_IS_ACTIVE') === '1' && (
              <Button
                style={menuSelected === 6 ? { ...style.itemmenu, ...style.itemSelected } : style.itemmenu}
                type="text"
                onClick={() => history.push(`/service-invoicing/${id}`)}
                icon={<FileTextOutlined style={{ color: '#341EFF' }} />}
              >
                {' '}   <FormattedMessage id="invoicing"/>
              </Button>
            )}
            <Button
              style={menuSelected === 7 ? { ...style.itemmenu, ...style.itemSelected } : style.itemmenu}
              type="text"
              onClick={() => history.push(`/service/close/${id}`)}
              icon={<img style={{ width: 15, marginRight: 5 }} src={entregar_icon} />}
            >
              <FormattedMessage id="servicesDetails.deliverVehicle" />
            </Button>
          </Space>
        </Col>
      </Row>
    </>
  );
}

const style = {
  itemmenu: { fontSize: 12, fontWeight: 'bolder', color: '#4d4d4d' },
  itemSelected: { backgroundColor: '#dad9d9' }
}

const mapState = (state) => ({
  settings: state.settings
});

export default injectIntl(connect(mapState, {
  
})(MenuServiceDetail));
