import React, { useEffect, useState } from "react";
import ServiceLayout from "../../layouts/ServiceLayout";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from 'react-redux';
import { axiosApi, API, urlfull } from "../../lib/utils/axiosApi";
import { getServiceDetail } from "../../redux/serviceOrderDuck";
import { getSupplierAction } from "../../redux/settingsDuck";
import { useParams, Link, useHistory } from "react-router-dom";
import { Global, css } from "@emotion/react";
import { floatNumbers, ruleRequired } from "../../lib/utils/rules";
import KSelect from "../../components/common/elements/KSelect/KSelect";
import KButton from "../../components/common/elements/KButton/KButton";
import KModal from "../../components/common/elements/KModal/KModal";
import { fileToBase64 } from "../../services/kikert/utils"
import PreviewAudatexData from "../../components/valuation/PreviewAudatexData"
import { getParts } from '../../redux/serviceOrderDuck'
import useSettingsKikert from "../../hooks/useSettingsKikert";
import useFetch from "../../hooks/useFetch";
import locale from "antd/es/date-picker/locale/es_ES";
import NumericInput from "../../components/common/NumericInput";
// import { CSVLink } from "react-csv";
import {
  Form,
  Input,
  Popconfirm,
  Layout,
  Button,
  Space,
  Upload,
  Tag,
  Row,
  Col,
  Card,
  Dropdown,
  Modal,
  Alert,
  message,
  Typography,
  DatePicker,
  Checkbox,
  InputNumber,
  Spin
} from "antd";
import MenuServiceDetail from "../../components/services/MenuServiceDetail";
import SectionTitle from "../../components/common/elements/SectionTitle/SectionTitle";
import moment from "moment";
// import { async } from "@firebase/util";
import { downloadFile } from "../../lib/utils/utils";
import _ from "lodash";
import NumberFormat from "../../components/common/NumberFormat";
import { CustomTable } from "../../components/KComponents";
import SelectParts from "../../components/Selects/SelectParts2";
import SelectItems from "../../components/Selects/SelectItems";
import {
  PlusOutlined, DownloadOutlined, CheckOutlined, EditOutlined, DeleteOutlined, CloseOutlined, UpOutlined,
  DownOutlined, PauseOutlined, InboxOutlined, UploadOutlined, FileSearchOutlined, ThunderboltOutlined
} from '@ant-design/icons';
import useCustomTags from "../../hooks/useCustomTags";
import MainLayoutv2 from '../../layouts/MainLayoutv2';
import SelectSuppliers from "../../components/Selects/SelectSuppliers";

const { TextArea } = Input;

const ServiceValuation = ({ intl, getServiceDetail, serviceOrder, members,getSupplierAction, getParts }) => {
  let { id } = useParams();
  const { getCustomTag } = useCustomTags()
  const { settingsKikert, getMetaValue } = useSettingsKikert()
  const { Text, Link } = Typography;
  const [form] = Form.useForm();
  const [formCreate] = Form.useForm();
  const [formBudget] = Form.useForm();
  const [formEditBudget] = Form.useForm();
  const [loadingReport, setLoadingReport] = useState(false)
  const [loadingModal, setLoadingModal] = useState(false)
  const [loadingCSV, setLoadingCSV] = useState(false);
  const [loadingFinish, setLoadingFinish] = useState(false);
  const [loading, setLoading] = useState(false);
  const [valuationCSV, setValuationCSV] = useState([]);

  const [usersOptions, setUsersOptions] = useState([]);
  const [jobTypes, setJobTypes] = useState([]);
  const [valuation, setValuation] = useState(null);
  const [savingBudget, setSavingBudget] = useState(false)
  const [savingValuation, setSavingValuation] = useState(false);
  const [valuationStatus, setValuationStatus] = useState(null)
  const [currentEdit, setCurrentEdit] = useState(null)
  const [openModal, setOpenModal] = useState(false)
  const [openImportCSVModal, setOpenImportCSVModal] = useState(false)
  const [openModalStatus, setOpenModalStatus] = useState(false)
  const [openModalDelete, setOpenModalDelete] = useState(false)
  const [openModalCreatePart, setOpenModalCreatePart] = useState(false)
  const [fileList, setFileList] = useState([]);
  const [fileListCsv, setFileListCsv] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [loadingParts, setLoadingParts] = useState(false);
  const [modalMode, setModalMode] = useState('upload');
  const [audaData, setAudaData] = useState(null);
  const [rowSelected, setRowSelected] = useState([]);
  const [editRow, seteditRow] = useState({
    qty: null,
    job_type: null,
    concept: null,
    price: null,
    total: null
  });
  const [items, setItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [urlSupplierCat, setUrlSupplierCat] = useState(API.BUSINESS.SUPPLIER_CATEGORY)
  const [showReports, setShowReports] = useState(false)

  const supplierCatResp = useFetch(urlSupplierCat)



  const reportBudgetParts = async () => {
    setLoadingReport(true)
    try {
      const res = await axiosApi.get(`${urlfull}service/order/${id}/build_report/?type=BUDGET_ONLY_PARTS_WITH_PRICE`, { responseType: 'blob' })
      downloadFile(res.data, `valuacion_partes_${_.get(
        serviceOrder,
        "serviceDetail.folio",
        ""
      )}`, 'application/pdf')
      console.log(res)
    } catch (e) {
      console.log(e)
    } finally {
      setShowReports(false)
      setLoadingReport(false)
    }
  }

  const reportBudgetComplements = async () => {
    setLoadingReport(true)
    try {
      const res = await axiosApi.get(`${urlfull}service/order/${id}/build_report/?type=BUDGET_ONLY_COMPLEMENTS_WITH_PRICE`, { responseType: 'blob' })
      downloadFile(res.data, `valuacion_complementos_${_.get(
        serviceOrder,
        "serviceDetail.folio",
        ""
      )}`, 'application/pdf')
      console.log(res)
    } catch (e) {
      console.log(e)
    } finally {
      setLoadingReport(false)
      setShowReports(false)
    }
  }

  const reportBudgetCosts = async () => {
    setLoadingReport(true)
    try {
      const res = await axiosApi.get(`${urlfull}service/order/${id}/build_report/?type=BUDGET_WITH_COSTS`, { responseType: 'blob' })
      downloadFile(res.data, `valuacion_${_.get(
        serviceOrder,
        "serviceDetail.folio",
        ""
      )}`, 'application/pdf')
      console.log(res)
    } catch (e) {
      console.log(e)
    } finally {
      setLoadingReport(false)
      setShowReports(false)
    }
  }

  const reportOrderWork = async () => {
    setLoadingReport(true)
    try {
      const res = await axiosApi.get(`${urlfull}service/order/${id}/build_report/?type=WORK_ORDER`, { responseType: 'blob' })
      downloadFile(res.data, `ordentrabajo_${_.get(
        serviceOrder,
        "serviceDetail.folio",
        ""
      )}`, 'application/pdf')
      console.log(res)
    } catch (e) {
      console.log(e)
    } finally {
      setLoadingReport(false)
      setShowReports(false)
    }
  }

  const reportPrice = async () => {
    setLoadingReport(true)
    try {
      const res = await axiosApi.get(`${urlfull}service/order/${id}/build_report/?type=BUDGET_WITH_PRICE`, { responseType: 'blob' })
      downloadFile(res.data, `cotizacion_${_.get(
        serviceOrder,
        "serviceDetail.folio",
        ""
      )}`, 'application/pdf')
      console.log(res)
    } catch (e) {
      console.log(e)
    } finally {
      setLoadingReport(false)
      setShowReports(false)
    }
  }

  const reportPriceIVA = async () => {
    setLoadingReport(true)
    try {
      const res = await axiosApi.get(`${urlfull}service/order/${id}/build_report/?type=BUDGET_WITH_PRICE_IVA&version=v2`, { responseType: 'blob' })
      downloadFile(res.data, `cotizacion_${_.get(
        serviceOrder,
        "serviceDetail.folio",
        ""
      )}`, 'application/pdf')
      console.log(res)
    } catch (e) {
      console.log(e)
    } finally {
      setLoadingReport(false)
      setShowReports(false)
    }
  }


  const selectForEdit = (item) => {
    setCurrentEdit(item.id);

    formEditBudget.setFieldsValue({
      qty: item.qty,
      job_type: item.job_type,
      concept: item.concept,
      cost: item.cost,
      price: item.price,
      total: item.total_cost,
      status: item.status,
      rejected_reason: item?.rejected_reason,
      sparepart: item?.sparepart?.id,
      code: item?.sparepart?.code,
      is_complement: item?.is_complement,
      priority: item?.priority
    });
  };

  const StatusItem = (status) => {
    if (status.status === "PENDING") {
      return <p><Tag color="gold">Pendiente</Tag></p>
    } else if (status.status === "AUTH") {
      return <p><Tag color="green">Autorizado</Tag></p>
    } else {
      return <p><Tag color="red">Rechazado</Tag></p>
    }
  }

  const StatusItemText = (status) => {
    if (status === "PENDING") {
      return 'Pendiente'
    } else if (status === "AUTH") {
      return 'Autorizado'
    } else {
      return 'Rechazado'
    }
  }

  const onCheckSelectRow = (e, item) => {
    let isSelected = e.target.checked
    let rows = [...rowSelected];
    if (isSelected) {
      rows.push(item.id)
    } else {
      let array = _.remove(rows, (id) => id !== item.id)
      rows = array
    }
    console.log(rows, item)
    setRowSelected(rows)
  }

  const checkAll = (e) => {
    let isSelected = e.target.checked
    if (!isSelected) {
      setRowSelected([])
      return
    }
    if (valuation.budget && valuation.budget.length > 0) {
      let arr = _.map(valuation.budget, (ele) => ele.id)
      setRowSelected(arr)
    }
  }


  const columns = [
    {
      title: <Checkbox onChange={checkAll} />,
      dataIndex: "select",
      key: "select",
      render: (text, item, idx) => {
        return <Checkbox
          checked={rowSelected.includes(item.id)}
          onChange={(e) => onCheckSelectRow(e, item)} />
      },
    },
    {
      title: "Prioridad ",
      dataIndex: "priority",
      key: "priority",
      render: (text, item, idx) => {
        let priority = priorityList.find((element) => element.value === item.priority);
        return currentEdit === item.id ? (
          <Form.Item name="priority">
            <KSelect
              style={{ width: 100 }}
              options={priorityList}
            />
          </Form.Item>
        ) : (
          <span>
            {
              item.priority === 1 ? <UpOutlined style={{ color: 'red' }} /> :
                item.priority === 3 ? <DownOutlined style={{ color: 'blue' }} /> : <PauseOutlined rotate={90} style={{ color: 'orange' }} />
            }
            {priority?.label}
          </span>

        )
      },
    },
    {
      title: "Estatus ",
      dataIndex: "status",
      key: "status",
      render: (text, item, idx) => {
        let status = estatusList.find((element) => element.value === text);
        return currentEdit === item.id ? (
          <>
            <Form.Item name="status" rules={[ruleRequired]}>
              <KSelect
                options={estatusList}
                style={{ width: 150 }}
                disabled={
                  !serviceOrder.serviceDetail?.order_dynamic_data.valuation
                }
              />
            </Form.Item>

            {/* {
              formEditBudget.getFieldValue("status") === 'REJECTED' && (
                <Form.Item name="rejected_reason">
                  <Input placeholder="Razón de rechazo" size={'small'} className={"kinput"} />
                </Form.Item>
              )
            } */}


          </>
        ) : (
          <>
            <StatusItem status={status.value} />
            {/* {
              status.value === 'REJECTED' && <>{'Razón de rechazo:'} {item?.rejected_reason}</>
            } */}

          </>
        )
      },
    },
    {
      title: "Cantidad",
      dataIndex: "qty",
      key: "qty",
      render: (text, item, idx) => {
        return currentEdit === item.id ? (
          <Form.Item name="qty" rules={[floatNumbers, ruleRequired]}>
            <Input size={'small'} className={"kinput"} />
          </Form.Item>
        ) : (
          <b>{text}</b>
        );
      },
    },
    {
      title: "Tipo de trabajo",
      dataIndex: "job_type",
      key: "job_type",
      render: (text, item, idx) => {
        let job_item = jobTypes.find((job) => job.value === text);
        return currentEdit === item.id ? (
          <Form.Item name="job_type" rules={[ruleRequired]}>
            <KSelect options={jobTypes} style={{ width: 150 }} />
          </Form.Item>
        ) : (
          <b>{job_item?.label}</b>
        );
      },
    },
    {
      title: "Tipo de proveedor",
      dataIndex: "supplier_category",
      key: "supplier_category",
      render: (text, item, idx) => {
        let job_item = jobTypes.find((job) => job.value === text);
        return currentEdit === item.id ? (
          <Form.Item name="supplier_category">
            <KSelect options={supplierCatResp?.data?.results?.map(i => {
              return { value: i.id, label: i.name }
            })} style={{ width: 150 }} />
          </Form.Item>
        ) : (
          <b>{item?.supplier_category_name}</b>
        );
      },
    },
    {
      title: "Refacción",
      dataIndex: "part",
      key: "part",
      render: (text, item, idx) => {
        return currentEdit === item.id ? (
          <SelectParts name="sparepart" onSetPart={(part) => onSetPart(part, true)} />) : (
          <b>{item?.sparepart?.name}</b>)
      },
    },
    {
      title: "Núm. parte",
      dataIndex: "code",
      key: "code",
      render: (text, item, idx) => {
        return currentEdit === item.id ? (
          <Form.Item name="code" >
            <Input className={"kinput"} disabled={true} maxLength={50} />
          </Form.Item>) : (
          <b>{item?.sparepart?.code}</b>)
      },
    },
    {
      title: "Concepto",
      dataIndex: "concept",
      key: "concept",
      render: (text, item, idx) => {
        return currentEdit === item.id ? (
          <Form.Item name="concept" rules={[ruleRequired]}>
            <Input className={"kinput"} maxLength={50} />
          </Form.Item>
        ) : (
          <>
            <b>{text}</b><br></br>
            {
              item?.item && (
                <>
                  <small>Unidad SAT: {item?.item?.sat_unit?.code}</small><br></br>
                  <small>Concepto SAT: {item?.item?.sat_concept?.code}</small>
                </>
              )
            }
          </>
        );
      },
    },
    {
      title: "Costo unitario",
      dataIndex: "cost",
      key: "cost",
      render: (text, item, idx) => {
        return currentEdit === item.id ? (
          <Form.Item
            name="cost"
            rules={[floatNumbers, ruleRequired]}
          >
            <Input className={"kinput"} />
          </Form.Item>
        ) : (
          <b>{
            currencyFormat(parseFloat(text))}</b>
        );
      },
    },
    {
      title: "Precio unitario",
      dataIndex: "price",
      key: "price",
      render: (text, item, idx) => {
        return currentEdit === item.id ? (
          <Form.Item name="price" rules={[floatNumbers, ruleRequired]}>
            <Input className={"kinput"} />
          </Form.Item>
        ) : (
          <b>{currencyFormat(parseFloat(text))}</b>
        );
      },
    },
    {
      title: "Precio total",
      dataIndex: "total_price",
      key: "total_price",
      render: (text, item, idx) => {
        return currentEdit === item.id ? (
          <Form.Item name="total">
            <Input readOnly className={"kinput"} />
          </Form.Item>
        ) : (
          <b>{currencyFormat(parseFloat(text))}</b>
        );
      },
    },
    {
      title: "Diferencia",
      key: "diff",
      render: (text, item, idx) => {
        const diff = (parseFloat(item?.qty || 0) * parseFloat(item.price)) - (parseFloat(item?.qty || 0) * parseFloat(item.cost))
        return (
          <b>{currencyFormat(diff)}</b>
        )

      },
    },
    {
      title: "Complemento",
      dataIndex: "is_complement",
      key: "is_complement",
      width: 10,
      render: (text, item, idx) => {
        return currentEdit === item.id ? (
          <Form.Item name="is_complement" valuePropName="checked">
            <Checkbox>Si</Checkbox>
          </Form.Item>
        ) : (
          <p style={{ textAlign: 'center' }}>{item.is_complement ? <CheckOutlined /> : ''}</p>
        );
      },
    },
    {
      title: "Acciones",
      key: "status",
      render: (text, item, idx) => {
        return currentEdit === item.id ? (
          <Space size={20}>
            <Link onClick={() => formEditBudget.submit()}>
              <CheckOutlined style={{ color: 'blue' }} />
            </Link>
            <Link onClick={() => setCurrentEdit(null)}>
              <CloseOutlined style={{ color: 'red' }} />
            </Link>
          </Space>
        ) : (
          <Space size={20}>
            <Link disabled={serviceOrder.serviceDetail?.status === 'CLOSED'} style={{ color: 'blue' }} onClick={() => selectForEdit(item)}>
              <EditOutlined />
            </Link>
            <Popconfirm
              placement="top"
              title={"¿Eliminar este elemento?"}
              onConfirm={() => deleteBudgetItem(item)}
              okText="Si"
              cancelText="No"
            >
              <Link disabled={serviceOrder.serviceDetail?.status === 'CLOSED'} style={{ color: 'red' }}><DeleteOutlined /></Link>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  const getTotalEdit = () => {
    let qty = formEditBudget.getFieldValue('qty') ? formEditBudget.getFieldValue('qty') : 0;
    let price = formEditBudget.getFieldValue('price') ? formEditBudget.getFieldValue('price') : 0;
    let total = qty * price;
    formEditBudget.setFieldsValue({
      total: total,
    });
  }

  const getExcelValuation = async (is_sat = '') => {
    setLoadingReport(true)
    try {
      let url = `/workshop/export-valuatiion-xls/${valuation.id}?is_sat=${is_sat}`;
      console.log('URL', url)
      const res = await axiosApi.get(`${url}`, { responseType: 'blob' })
      downloadFile(res.data, `valuation`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
      console.log(res)
    } catch (e) {
      console.log(e)
    } finally {
      setLoadingReport(false)
    }
  }

  const getValuators = async () => {
    try {
      let resp = await axiosApi.get(API.WORKFLOW.USERS_WORKFLOW);
      //console.log('resp users', resp)
      let users = resp.data.map((item) => {
        return {
          value: item.id,
          label: `${item.first_name} ${item.last_name}`,
        };
      });
      setUsersOptions(users);
    } catch (error) {

    }
  }

  const getItems = async () => {
    try {
      let resp = await axiosApi.get(API.INVENTORY.ITEMS);
      const item_list = resp.data.results
      //console.log('resp ITEMS', item_list)
      setItems(item_list)
    } catch (error) {
      console.error("error items", error)
    }
  }

  useEffect(() => {
    if (id) {
      getServiceDetail(id);
      getValuators()
      getJobType()
      getItems()
    }
  }, []);

  useEffect(() => {
    getSupplierAction(1, null, 1000)
  }, [])

  const getValuation = async () => {
    setLoading(true)
    try {
      const resp = await axiosApi.get(
        API.WORKSHOP.WORKSHOP_VALUATION +
        serviceOrder.serviceDetail.order_dynamic_data.valuation.id
      );
      setValuation(resp.data);
      //debugger;
      processSaleOrderCSV(resp?.data?.budget)
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false)
    }
  }

  const processSaleOrderCSV = (dataIn) => {
    const headers = ['Estatus', 'Prioridad', 'Cantidad', 'Tipo de trabajo', 'Refaccion', 'Num.Parte', 'Concepto', 'Costo unitario', 'Precio unitario', 'Diferencia', 'Precio total', 'Complemento', 'id'];
    let newData = _.map(dataIn, (f) => {

      let isComplement = f.is_complement ? 'Si' : 'No';

      return [
        StatusItemText(f.status),
        f.priority,
        f.qty,
        f?.job_type_obj?.name,
        f?.sparepart?.name,
        f?.sparepart?.code,
        f.concept,
        f.cost,
        f.price,
        (parseFloat(f?.qty || 0) * parseFloat(f.price)) - (parseFloat(f?.qty || 0) * parseFloat(f.cost)),
        f.price * f.qty,
        isComplement,
        f?.id,
      ]
    })
    newData.unshift(headers)
    setValuationCSV(newData)
  };

  const getJobType = async () => {
    try {
      let resp = await axiosApi.get(API.WORKSHOP.WORKSHOP_JOB_TYPE);
      let options = resp.data.results.map(item => {
        return { value: item.id, label: item.name }
      })
      setJobTypes(options);
    } catch (error) {

    }
  };

  const saveBudget = async (values) => {
    let qty = formBudget.getFieldValue('qty') ? formBudget.getFieldValue('qty') : 0;
    let price = formBudget.getFieldValue('price') ? formBudget.getFieldValue('price') : 0;
    let total = qty * price;

    if (values?.is_complement === undefined) {
      values.is_complement = false;
    }

    values["valuation"] = serviceOrder.serviceDetail.order_dynamic_data.valuation.id
    values["total"] = total;
    values["item"] = selectedItem?.id

    setSavingBudget(true);
    try {
      let resp = await axiosApi.post(API.WORKSHOP.WORKSHOP_BUDGET, values);
      if (200 <= resp.status < 400) {
        message.success(intl.formatMessage({ id: "item.saveSuccess" }));
        formBudget.resetFields()
        setSelectedItem(null)
        getValuation()
      }
    } catch (error) {

    } finally {
      setSavingBudget(false);
    }
  }

  const estatusList = [
    { value: "PENDING", label: 'Pendiente' },
    { value: "AUTH", label: 'Autorizado' },
    { value: "REJECTED", label: 'Rechazado' }
  ]

  const priorityList = [
    { value: 1, label: ` Alta` },
    { value: 2, label: ' Media' },
    { value: 3, label: ' Baja' }
  ]



  useEffect(() => {
    if (
      serviceOrder.serviceDetail &&
      serviceOrder.serviceDetail.order_dynamic_data &&
      serviceOrder.serviceDetail.order_dynamic_data.valuation
    ) {
      getValuation();
    }
  }, [serviceOrder.serviceDetail]);


  useEffect(() => {
    if (valuation) {
      /* getBudget(); */
      setValuationStatus(valuation.status)
      form.setFieldsValue({
        appraiser_technician: valuation.appraiser_technician,
        deductible_amount: valuation.deductible_amount,
        demerit_amount: valuation.demerit_amount,
        budget_date: valuation.budget_date ? moment(valuation.budget_date) : null,
        quotation_date: valuation.quotation_date ? moment(valuation.quotation_date) : null,
        auth_date: valuation.auth_date ? moment(valuation.auth_date) : null,
        send_date: valuation.send_date ? moment(valuation.send_date) : null,
      });
    } else {
      form.setFieldsValue({
        deductible_amount: 0.00,
        demerit_amount: 0.00
      });
    }
  }, [valuation]);




  const sendValuation = async (values) => {
    setSavingValuation(true)
    values["workshop_order"] = serviceOrder.serviceDetail.order_dynamic_data.id;
    if (!values?.auth_date) {
      delete values?.auth_date;
    } else {
      values["auth_date"] = moment(values.auth_date).format("YYYY-MM-DD");
    }

    if (!values?.send_date) {
      delete values?.send_date;
    } else {
      values["send_date"] = moment(values.send_date).format("YYYY-MM-DD");
    }

    if (!values?.budget_date) {
      delete values?.budget_date;
    } else {
      values["budget_date"] = moment(values.budget_date).format("YYYY-MM-DD");
    }

    if (!values?.quotation_date) {
      delete values?.quotation_date;
    } else {
      values["quotation_date"] = moment(values.quotation_date).format("YYYY-MM-DD");
    }


    try {
      let resp
      if (!serviceOrder.serviceDetail.order_dynamic_data.valuation) {
        resp = await axiosApi.post(API.WORKSHOP.WORKSHOP_VALUATION, values);
      } else {
        resp = await axiosApi.patch(
          `${API.WORKSHOP.WORKSHOP_VALUATION}${serviceOrder.serviceDetail?.order_dynamic_data.valuation.id}/`,
          values
        );
      }
      getServiceDetail(id);
      message.success(intl.formatMessage({ id: "item.saveSuccess" }));
    } catch (error) {
      console.log('error', error)
    } finally {
      setSavingValuation(false);
    }
  }

  useEffect(() => {
    console.log("valuation", valuation);
  }, [valuation])

  const updateStateValuation = async (value) => {
    setLoadingFinish(true)
    try {
      let data = { status: value };
      let resp = await axiosApi.patch(
        API.WORKSHOP.WORKSHOP_VALUATION + valuation.id + "/",
        data
      );
      if (resp.status === 200) {
        message.success(
          intl.formatMessage({ id: "item.saveSuccess" })
        );
        setValuationStatus(value);
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        message.error(error?.response?.data?.message)
      } else {
        message.error("Ocurrió un error, porfavor intenta mas tarde")
      }
    } finally {
      setLoadingFinish(false)
    }
  }

  const onChangeForm = () => {
    getTotal()
    getDiffCostAndPrice()
  }

  const getTotal = () => {
    let qty = formBudget.getFieldValue('qty') ? formBudget.getFieldValue('qty') : 0;
    let price = formBudget.getFieldValue('price') ? formBudget.getFieldValue('price') : 0;
    let total = qty * price;

    formBudget.setFieldsValue({
      total: total
    })
  }

  const getDiffCostAndPrice = () => {
    const qty = formBudget.getFieldValue('qty') || 0
    let cost = formBudget.getFieldValue('cost') ? formBudget.getFieldValue('cost') : 0;
    let price = formBudget.getFieldValue('price') ? formBudget.getFieldValue('price') : 0;
    let total = (qty * price) - (qty * cost)
    formBudget.setFieldsValue({
      diff: total,
    });
  }

  const updateBudget = async (values) => {
    let qty = formEditBudget.getFieldValue("qty")
      ? formEditBudget.getFieldValue("qty")
      : 0;
    let price = formEditBudget.getFieldValue("price")
      ? formEditBudget.getFieldValue("price")
      : 0;
    let total = qty * price;

    values["valuation"] =
      serviceOrder.serviceDetail.order_dynamic_data.valuation.id;
    values["total"] = total;

    setSavingBudget(true);
    try {
      let resp = await axiosApi.patch(API.WORKSHOP.WORKSHOP_BUDGET + currentEdit + "/", values);
      console.log("resp", resp);
      if (200 <= resp.status < 400) {
        message.success(intl.formatMessage({ id: "item.saveSuccess" }));
        formEditBudget.resetFields();
        setCurrentEdit(null);
        getValuation();
        getDiffCostAndPrice()
      }
    } catch (error) {
    } finally {
      setSavingBudget(false);
    }
  };

  const UploaderProps = {
    multiple: false,
    onRemove: file => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: file => {
      // setFileList([...fileList, file]);
      setFileList([file]);
      return false;
    },
    fileList,
  };

  const UploaderPropsCsvValuation = {
    multiple: false,
    onRemove: file => {
      const index = fileListCsv.indexOf(file);
      const newFileList = fileListCsv.slice();
      newFileList.splice(index, 1);
      setFileListCsv(newFileList);
    },
    beforeUpload: file => {
      setFileListCsv([file]);
      return false;
    },
    fileList,
  };

  const onSetPart = (part, idEdit = false) => {
    if (part && !idEdit) {
      formBudget.setFieldsValue({
        concept: part.name,
        cost: part.cost,
        price: part.price,
        code: part.code
      })
      getTotal();
      getDiffCostAndPrice()
    }

    if (part && idEdit) {
      formEditBudget.setFieldsValue({
        cost: part.cost,
        price: part.price,
        code: part.code,
        concept: part.name,
        priority: part.priority
      });

      getTotalEdit()

    }

    console.log('onSetPart', part, formBudget.getFieldsValue())
  }

  const onSetItem = (item) => {
    formBudget.setFieldsValue({
      concept: item?.name,
      price: item?.price,
      cost: item?.cost
    })
    setSelectedItem(item)
  }

  const handleUpload = async () => {
    if (valuation?.had_imported_parts) {
      saveOnlyAudaFile()
      return
    }
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append('file', file);
    });
    formData.append('orderId', id)

    // You can use any AJAX library you like
    try {
      setUploading(true);
      const response = await axiosApi.post(API.WORKSHOP.WORKSHOP_PDF_AUDA, formData)
      setAudaData(response.data)
      setModalMode("showData")
    } catch (error) {
      console.error("Error")
    } finally {
      setUploading(false);
    }
  };

  const handleUploadCsvValuation = async () => {
    const formData = new FormData();
    fileListCsv.forEach((file) => {
      formData.append('file', file);
    });
    formData.append('orderId', id)
    formData.append('valuationId', valuation?.id)

    // You can use any AJAX library you like
    try {
      setUploading(true);
      const response = await axiosApi.post(API.WORKSHOP.WORKSHOP_IMPORT_CSV_VALUATION, formData)
      const data = response?.data
      const status = data?.status
      if (!status) {
        const details = data?.details
        message.error(`Error al importar datos: ${details}`, 15)
      } else {
        message.success('Datos importados de forma exitosa')
      }
      getValuation()
      setOpenImportCSVModal(false)
    } catch (error) {
      console.error("Error")
      if (error?.response?.data?.message) {
        message.error(error?.response?.data?.message)
      } else {
        message.error("Ocurrió un error, porfavor intenta mas tarde")
      }
    } finally {
      setUploading(false);
    }
  };

  const saveOnlyAudaFile = async () => {
    try {
      setUploading(true)
      const fileToLoad = fileList[0];
      const values = {
        "orderId": id,
        "fileBase64": await fileToBase64(fileToLoad)
      }
      let resp = await axiosApi.post(API.WORKSHOP.WORKSHOP_BULK_BUDGET, values);
      if (200 <= resp.status < 400) {
        message.success("Datos guardados");
        setOpenModal(false)
        //getValuation()
      }
    } catch (error) {
      message.error("No se pudieron guardar los datos");
    } finally {
      setUploading(false)
    }
  }


  const deleteBudgetItem = async (item) => {
    console.log("item", item);
    try {
      let resp = await axiosApi.delete(API.WORKSHOP.WORKSHOP_BUDGET + item.id + "/");
      if (resp.status === 204) {
        message.success(intl.formatMessage({ id: "item.deleteSuccess" }));
        getValuation()
      }
    } catch (error) {
    } finally {
      /* setSavingBudget(false); */
    }
  }

  const currencyFormat = (num) => {
    return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

  const deleteMassive = async () => {
    let req = {
      budget_ids: rowSelected
    }
    setLoadingModal(true)
    try {
      const res = await axiosApi.post(API.WORKSHOP.WORKSHOP_VALUATION_DELETE_MASSIVE, req)
      message.success('Eliminado correctamente')
      setOpenModalDelete(false)
      getValuation()
    } catch (e) {
      message.error('No se pudo eliminar, por favor intenta mas tarde')
    } finally {
      setLoadingModal(false)
    }
  }

  const createPart = async (values) => {
    setLoadingParts(true)
    try {
      let fullURL = `${urlfull}spareparts/sparepart/`;
      const res = await axiosApi.post(fullURL, values)
      formCreate.resetFields()
      await getParts()
    } catch (e) {
      console.log(e)
      message.error('Hubo un error al crear la refacción, intente mas tarde')
    } finally {
      setLoadingParts(false)
      setOpenModalCreatePart(false)
    }
  }



const FormCreatePart = () => {
    return (
      <Form layout={'vertical'} form={formCreate} name="control-hooks" onFinish={createPart}>
        <Form.Item
          name="id"
          label="id"
          hidden={true}
        >
          <Input className={'kinput'} />
        </Form.Item>

        <Form.Item
          name="name"
          label="Nombre"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input className={'kinput'} />
        </Form.Item>
        <Form.Item
          name="code"
          label="Código/Num. Parte"
        >
          <Input className={'kinput'} />
        </Form.Item>

        <SelectSuppliers style={{ marginBottom: 20 }} allowClear={true} name={'supplier'} label="Proveedor" />


        <Form.Item
          name="cost"
          label="Costo"
          style={{ marginTop: 30 }}
        >
          <InputNumber
            style={{ width: '100%' }}
            className={'kinput'}
            formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}

          />
        </Form.Item>


        <Form.Item
          name="price"
          label="Precio"
          style={{ marginTop: 30 }}
        >
          <InputNumber
            style={{ width: '100%' }}
            className={'kinput'}
            formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
          />
        </Form.Item>
        <Form.Item>
          <KButton text={'Cancelar'} type={'secondary'} onClick={() => setOpenModalCreatePart(false)} />
          <KButton text={'Guardar'} type="primary" htmlType="submit" />
        </Form.Item>
      </Form>
    )
  }

  const FooterItems = () => {

    if (valuation?.budget) {
      const { budget } = valuation;
      const isComplementArr = _.filter(budget, (ele) => ele.is_complement)
      return <p>{`Elementos: ${budget.length}, Complementos: ${isComplementArr.length}`}</p>
    }
    return <p></p>
  }


  const countTypeValuation = (valuation, type) => {
    let count = 0;
    if (valuation?.budget) {
      const arr = _.filter(valuation?.budget, (ele) => ele?.job_type_obj?.code === type);
      if (arr) {
        arr.map((ele) => {
          count = count + parseFloat(ele.qty);
        })
      }
    }
    return count
  }

  const changeStatusMassive = async (values) => {
    let req = {
      budget_ids: rowSelected,
      ...values
    }
    console.log('REQQ', req)
    setLoadingModal(true)
    try {
      const res = await axiosApi.post(API.WORKSHOP.WORKSHOP_VALUATION_BUDGET_MASSIVE, req)
      message.success('Actualizado correctamente')
      setOpenModalStatus(false)
      getValuation()
    } catch (e) {
      message.error('No se pudo actualizar, por favor intenta mas tarde')
    } finally {
      setLoadingModal(false)
    }
  }
  const isSatValuationActive = getMetaValue("SAT_VALUATION") === "1"
  const isDemeritAmountHide = getMetaValue("HIDE_DEMERIT_AMOUNT") == "1"
  const isDeductibleAmountHide = getMetaValue("HIDE_DEDUCTIBLE_AMOUNT") == "1"

  const suppCatList = supplierCatResp?.data?.results ? supplierCatResp?.data?.results?.map(i => {
    return { value: i.id, label: i.name }
  }) : []

  return (
    <>
      <Global
        styles={css`
          .formBudget {
            display: flex;
            flex-direction: row;
            margin-bottom:20px;
          }
          .formBudget > div {
            flex: 1 1 auto;
            margin: 5px; /* and that, will result in a 10px gap */
          }
          .formBudget > .columnQty {
            max-width: 100px;
          }
          .formBudget > .columnConcept {
            min-width: 230px;
            flex: 5 1 auto;
          }
          .formBudget .ant-form-item{
            margin-bottom:0px;
          }
          .mt-auto{
            margin-top: auto;
          }
        `}
      />
      <MainLayoutv2
        isServiceDetail={true}
        title={intl.formatMessage({ id: "servicesDetails.servicesDetails" })}
        index="expedientes"
      >

        <KModal
          title={'Cambiar status masivo'}
          onCancel={() => setOpenModalStatus(false)}
          loading={loadingModal}
          visible={openModalStatus}>
          <Row>
            <Col span={24}>
              <Form onFinish={changeStatusMassive}>
                <Form.Item
                  name="status"
                  label={'Nuevo status'}
                  rules={[ruleRequired]}
                >
                  <KSelect options={[
                    { label: 'No cambiar el valor', value: "N/A" },
                    { label: 'Pendiente', value: "PENDING" },
                    { label: 'Autorizado', value: "AUTH" },
                    { label: 'Rechazado', value: "REJECTED" }
                  ]} />
                </Form.Item>
                <Form.Item
                  name="supplier_category"
                  label={'Nuevo tipo de proveedor'}
                  rules={[ruleRequired]}
                >
                  <KSelect value={'N/A'} options={[
                    { label: 'No cambiar el valor', value: "N/A" },
                    { label: 'Eliminar', value: "-1" },
                    ...suppCatList
                  ]} />
                </Form.Item>
                <Form.Item
                >
                  <KButton text={'Cancelar'} type={'secondary'} onClick={() => setOpenModalStatus(false)} />
                  <KButton text={'Guardar'} htmlType={'submit'} />
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </KModal>


        <KModal
          title={'Eliminar masivo'}
          onCancel={() => setOpenModalDelete(false)}
          loading={loadingModal}
          visible={openModalDelete}>
          <Row>
            <Col span={24}>
              <Alert message="Estás a punto de eliminar los elementos, esta acción no es reversible, ¿Continuar?" type="error" />
              <br />
              <Form>
                <Form.Item
                >
                  <Space>
                    <KButton text={'Cancelar'} type={'secondary'} onClick={() => setOpenModalDelete(false)} />
                    <KButton text={'Aceptar'} onClick={deleteMassive} />
                  </Space>

                </Form.Item>
              </Form>
            </Col>
          </Row>
        </KModal>


        <KModal
          title={'Nuevo'}
          onCancel={() => setOpenModalCreatePart(false)}
          loading={loadingParts}
          visible={openModalCreatePart}>
          <Row>
            <Col span={24}>
              <FormCreatePart />
            </Col>
          </Row>
        </KModal>

        <KModal
          title={'Nuevo'}
          onCancel={() => setOpenImportCSVModal(false)}
          loading={uploading}
          visible={openImportCSVModal}>
          <Col span={24} style={{ textAlign: "center" }}>
            <>
              <Upload.Dragger {...UploaderPropsCsvValuation}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">Da clic aquí o arrastra el archivo CSV o Excel</p>
                <p className="ant-upload-hint">Solo se permite un archivo a la vez.</p>
              </Upload.Dragger>
              <Button
                type="primary"
                onClick={handleUploadCsvValuation}
                disabled={fileListCsv.length === 0}
                loading={uploading}
                style={{
                  marginTop: 16,
                }}
              >
                {uploading ? 'Subiendo' : "Procesar archivo"}
              </Button>
            </>
          </Col>
        </KModal>

        <Layout style={{ marginTop: 10 }}>
          <Card className="card_details_service" style={{ paddingBottom: 30 }}>
            <MenuServiceDetail menuSelected={2} />


            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: 20,
                marginBottom: 30,
              }}
            >


              {
                serviceOrder?.serviceDetail?.status !== 'CLOSED' &&
                <div>
                  <Space direction="horizontal">
                    {valuationStatus && valuationStatus === "PROGRESS" && (
                      <Button
                        icon={<ThunderboltOutlined />}
                        className="btn-gray btn-report-action"
                        size="large"
                        block
                        loading={loadingFinish}
                        onClick={() => {
                          updateStateValuation("DONE");
                        }}
                      >
                        {/* <FormattedMessage id="services.page.filter" /> */}
                        Marcar valuación terminada
                      </Button>
                    )}
                    {valuationStatus && valuationStatus === "DONE" && (
                      <Button
                        size="large"
                        loading={loadingFinish}
                        icon={<ThunderboltOutlined />}
                        className="btn-string-blue btn-report-action"
                        onClick={() => {
                          updateStateValuation("PROGRESS");
                        }}
                        block
                      >
                        {/* <FormattedMessage id="layout.createNew" /> */}
                        Marcar valuación en proceso
                      </Button>
                    )}
                  </Space>
                </div>
              }
              <Button size="large" icon={<FileSearchOutlined />} className="btn-string-blue btn-report-action" onClick={() => setShowReports(true)} >Reportes</Button>
            </div>
            <SectionTitle
              title={intl.formatMessage({ id: "servicesDetails.valuation" })}
            />
            <Spin spinning={savingValuation}>
              <Form
                form={form}
                onFinish={sendValuation}
                layout={"vertical"}
                style={{ marginTop: 20, marginBottom: 20 }}
              >
                <Row gutter={20}>
                  <Col md={8}>
                    <Form.Item
                      name="appraiser_technician"

                      label={getCustomTag('tech_valuator')}
                    >
                      <KSelect disabled={serviceOrder.serviceDetail?.status === 'CLOSED'} showSearch options={usersOptions} />
                    </Form.Item>
                  </Col>
                  {
                    !isDeductibleAmountHide && (
                      <Col md={6}>
                        <Form.Item
                          name={"deductible_amount"}
                          label={intl.formatMessage({
                            id: "servicesDetails.valuation.deductible_amount",
                          })}
                          rules={[floatNumbers, ruleRequired]}
                        >
                          <Input disabled={serviceOrder.serviceDetail?.status === 'CLOSED'} prefix="$" className={"kinput"} />
                        </Form.Item>
                      </Col>
                    )
                  }
                  {
                    !isDemeritAmountHide && (
                      <Col md={6}>
                        <Form.Item
                          name="demerit_amount"
                          label={intl.formatMessage({
                            id: "servicesDetails.valuation.demerit_amount",
                          })}
                          rules={[floatNumbers, ruleRequired]}
                        >
                          <Input prefix="$" disabled={serviceOrder.serviceDetail?.status === 'CLOSED'} className={"kinput"} />
                        </Form.Item>
                      </Col>
                    )
                  }
                  <Col md={6}>
                    <Form.Item
                      name="budget_date"
                      label={intl.formatMessage({
                        id: "servicesDetails.valuation.budget_date",
                      })}
                    >
                      <DatePicker
                        disabled={serviceOrder.serviceDetail?.status === 'CLOSED'}
                        className={"kinput"}
                        locale={locale}
                        format="DD-MM-YYYY"
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={6}>
                    <Form.Item
                      name="quotation_date"
                      label={intl.formatMessage({
                        id: "servicesDetails.valuation.quote_date",
                      })}
                    >
                      <DatePicker
                        disabled={serviceOrder.serviceDetail?.status === 'CLOSED'}
                        className={"kinput"}
                        format="DD-MM-YYYY"
                        locale={locale}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={6}>
                    <Form.Item
                      name="send_date"
                      disabled={serviceOrder.serviceDetail?.status === 'CLOSED'}
                      label={'Fecha envío'}
                    >
                      <DatePicker
                        className={"kinput"}
                        format="DD-MM-YYYY"
                        locale={locale}
                        disabled={serviceOrder.serviceDetail?.status === 'CLOSED'}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={6}>
                    <Form.Item
                      name="auth_date"
                      disabled={serviceOrder.serviceDetail?.status === 'CLOSED'}
                      label={intl.formatMessage({
                        id: "servicesDetails.valuation.authorization_date",
                      })}
                    >
                      <DatePicker
                        className={"kinput"}
                        format="DD-MM-YYYY"
                        locale={locale}
                        disabled={serviceOrder.serviceDetail?.status === 'CLOSED'}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={24}>
                    {
                      serviceOrder.serviceDetail?.status !== 'CLOSED' && <Form.Item>
                        <Button
                          className="btn-string-blue"
                          size="large"
                          htmlType="submit"
                        >
                          {serviceOrder.serviceDetail?.order_dynamic_data
                            ?.valuation ? (
                            <FormattedMessage id="update" />
                          ) : (
                            <FormattedMessage id="save" />
                          )}
                        </Button>
                      </Form.Item>
                    }

                  </Col>
                </Row>
              </Form>
            </Spin>
            <SectionTitle
              title={intl.formatMessage({
                id: "servicesDetails.valuation.budget",
              })}
            />
            <div style={{ display: "flex", marginBottom: 10, marginTop: 10 }}>
              <Space
                direction="horizontal"
                style={{ justifyContent: "center" }}
              >
                <p style={{ marginBottom: 0 }}>
                  <Text strong>
                    Total inicial: {' '}
                  </Text>
                  <NumberFormat number={(valuation && valuation.budget_initial_amount) || 0} prefix={'$'} />
                </p>
                <p style={{ marginBottom: 0 }}>
                  <Text strong>
                    Total autorizado: {' '}
                  </Text>
                  <NumberFormat number={(valuation && valuation.budget_auth_initial_amount) || 0} prefix={'$'} />
                </p>
                <p style={{ marginBottom: 0 }}>
                  <Text strong>
                    Diferencia: {' '}
                  </Text>
                  <NumberFormat number={(valuation && valuation.budget_diff_initial_amount) || 0} prefix={'$'} />
                </p>
              </Space>
            </div>
            <Row justify="center" gutter={50} style={{ marginTop: 30 }}>
              {valuation?.amount_budget &&
                Object.keys(valuation.amount_budget).map((key, i) => {
                  return (
                    <Col
                      style={{ fontSize: 17, background: "rgb(230, 230, 255)" }}
                    >
                      <Space direction="vertical" align="center">
                        <Text strong>{valuation.amount_budget[key].name}</Text>
                        <Text>
                          {countTypeValuation(valuation, key)}
                        </Text>
                        <Text strong type="secondary">
                          {/*{JSON.stringify(valuation)}*/}
                          {`C: ` + currencyFormat(valuation.amount_budget[key]?.cost_amount ?? 0)}

                        </Text>
                        <Text strong type="secondary">
                          {/*{JSON.stringify(valuation)}*/}
                          {`P: ` + currencyFormat(valuation.amount_budget[key]?.price_amount ?? 0)}

                        </Text>

                      </Space>
                    </Col>
                  );
                })}
            </Row>
            <div style={{ marginTop: 15 }}>

              <KButton
                type={"secondary"}
                text={
                  'Agregar refacción'
                }
                icon={<PlusOutlined />}
                disabled={
                  !serviceOrder.serviceDetail?.order_dynamic_data
                    .valuation
                }
                onClick={() => setOpenModalCreatePart(true)}
                style={{ marginTop: "auto" }}
              />

              <KButton
                type={"primary"}
                text={
                  "Importar desde pdf"
                }
                onClick={() => { setOpenModal(true); setModalMode("upload") }}
                disabled={!serviceOrder.serviceDetail?.order_dynamic_data
                  .valuation}
                loading={false}
                style={{ marginTop: "auto", marginBottom: "auto" }}
              />
              <KButton size={'large'} type={'secondary'}
                onClick={getExcelValuation}
                text={
                  loadingCSV ? <><FormattedMessage id="salesOrder.loading" /></> :
                    <DownloadOutlined />
                } />
              {
                isSatValuationActive && (
                  <KButton size={'large'} type={'secondary'}
                    onClick={() => getExcelValuation('1')}
                    text={
                      loadingCSV ? <><FormattedMessage id="salesOrder.loading" /></> :
                        <><DownloadOutlined /> {'SAT'}</>
                    } />
                )
              }

              <KButton
                type={"secondary"}
                text={
                  <UploadOutlined />
                }
                onClick={() => { setOpenImportCSVModal(true) }}
                disabled={!serviceOrder.serviceDetail?.order_dynamic_data
                  .valuation}
                loading={false}
                style={{ marginTop: "auto", marginBottom: "auto" }}
              />
              <br />
              <KModal
                title={'Subir archivo'}
                onCancel={() => { setOpenModal(false) }}
                loading={loadingModal}
                width={"100%"}
                visible={openModal}>
                <Col span={24} style={{ textAlign: "center" }}>
                  {
                    modalMode === 'upload' ? (
                      <>
                        <Upload.Dragger {...UploaderProps}>
                          <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                          </p>
                          <p className="ant-upload-text">Da clic aquí o arrastra el archivo pdf</p>
                          {
                            valuation?.had_imported_parts && <p className="ant-upload-text">Hemos detectado una importación de un archivo anterior, por lo tanto este archivo solo se guardará pero no se procesará.</p>
                          }
                          <p className="ant-upload-hint">Solo se permite un archivo a la vez.</p>
                        </Upload.Dragger>
                        <Button
                          type="primary"
                          onClick={handleUpload}
                          disabled={fileList.length === 0}
                          loading={uploading}
                          style={{
                            marginTop: 16,
                          }}
                        >
                          {uploading ? 'Subiendo' : valuation?.had_imported_parts ? "Guardar archivo como historial" : "Procesar archivo"}
                        </Button>
                      </>
                    ) : <PreviewAudatexData
                      orderId={id}
                      data={audaData}
                      valuationId={valuation?.id}
                      setLoadingModal={setLoadingModal}
                      jobTypes={jobTypes}
                      estatusList={estatusList}
                      fileList={fileList}
                      getValuation={getValuation}
                      setOpenModal={setOpenModal}
                      serviceOrder={serviceOrder} />
                  }
                </Col>
              </KModal>

              <Spin spinning={savingBudget}>

                {
                  serviceOrder.serviceDetail?.status !== 'CLOSED' && <Form
                    form={formBudget}
                    layout="vertical"
                    onFinish={saveBudget}
                    disabled={serviceOrder.serviceDetail?.status === 'CLOSED'}
                    loading={savingBudget}
                    style={{ marginTop: 15 }}
                    onChange={onChangeForm}
                  >
                    <Text>
                      Capture cada uno de los conceptos de trabajo que serán aplicados
                      a este vehiculo
                    </Text>
                    {
                      isSatValuationActive && (
                        <Row style={{ marginTop: 5 }}>
                          <Col xs={12} md={12}>
                            <SelectItems disabled={serviceOrder.serviceDetail?.status === 'CLOSED'} onSetPart={onSetItem} rules={[]} itemList={items} name="item" label={'Artículo'} />
                            {
                              selectedItem && (
                                <>
                                  <p>
                                    <b>Unidad de medida: {selectedItem?.sat_unit?.code} {selectedItem?.sat_unit?.description}.</b><br></br>
                                    <b>Concepto: {selectedItem?.sat_concept?.code} {selectedItem?.sat_concept?.description}</b>
                                  </p>
                                </>
                              )
                            }
                          </Col>
                        </Row>
                      )
                    }
                    <Row gutter={16}>
                      <Col xs={12} md={4}>
                        <Form.Item
                          className="mt-auto"
                          name="qty"
                          label={intl.formatMessage({
                            id: "amount",
                          })}
                          rules={[floatNumbers, ruleRequired]}
                        >
                          <Input
                            className={"kinput"}
                            disabled={
                              !serviceOrder.serviceDetail?.order_dynamic_data
                                .valuation
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={12} md={4}>
                        <Form.Item
                          className="mt-auto"
                          name="job_type"
                          label={intl.formatMessage({
                            id: "typeJob",
                          })}
                          rules={[ruleRequired]}
                        >
                          <KSelect
                            options={jobTypes}
                            style={{ minWidth: 150, width: "100% " }}
                            disabled={
                              !serviceOrder.serviceDetail?.order_dynamic_data
                                .valuation
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={12} md={8}>
                        <SelectParts disabled={serviceOrder.serviceDetail?.status === 'CLOSED'} onSetPart={onSetPart} rules={[ruleRequired]} name="sparepart" label={'Refacción'} />
                      </Col>
                      <Col xs={12} md={4}>
                        <Form.Item
                          className="mt-auto"
                          name="code"
                          label="Núm. Parte"
                        /* label={intl.formatMessage({
                        id: "Total",
                      })} */
                        >
                          <Input
                            className={"kinput"}
                            disabled={true}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={12} md={4}>
                        <Form.Item name="is_complement" label={"¿Es complemento?"} valuePropName="checked">
                          <Checkbox>Si</Checkbox>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}  >
                      <Col xs={12} md={12}>
                        <Form.Item
                          className="mt-auto"
                          name="concept"
                          extra={'**Máximo 150 caracteres'}
                          label={intl.formatMessage({
                            id: "concept",
                          })}
                          rules={[ruleRequired]}
                        >
                          <TextArea
                            className={"kinput"}
                            maxLength={150}
                            disabled={
                              !serviceOrder.serviceDetail?.order_dynamic_data
                                .valuation
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={12} md={4}>
                        <Form.Item
                          className="mt-auto"
                          name="cost"
                          label={intl.formatMessage({
                            id: "cost",
                          })}
                          rules={[floatNumbers, ruleRequired]}
                        >
                          <InputNumber
                            disabled={
                              !serviceOrder.serviceDetail?.order_dynamic_data
                                .valuation
                            }
                            style={{ width: '100%' }}
                            className={'kinput'}
                            formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}

                          />
                          {/*<Input
                            className={"kinput"}
                            disabled={
                              !serviceOrder.serviceDetail?.order_dynamic_data
                                  .valuation
                            }
                        />*/}
                        </Form.Item>
                      </Col>
                      <Col xs={12} md={4}>
                        <Form.Item
                          className="mt-auto"
                          name="price"
                          label={intl.formatMessage({
                            id: "price",
                          })}
                          rules={[floatNumbers, ruleRequired]}
                        >
                          <InputNumber
                            disabled={
                              !serviceOrder.serviceDetail?.order_dynamic_data
                                .valuation
                            }
                            style={{ width: '100%' }}
                            className={'kinput'}
                            formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}

                          />

                        </Form.Item>
                      </Col>
                      <Col xs={12} md={4}>
                        <Form.Item
                          className="mt-auto"
                          name="total"
                          label="Precio total"
                        /* label={intl.formatMessage({
                        id: "Total",
                      })} */
                        >
                          <InputNumber
                            readOnly
                            disabled={
                              !serviceOrder.serviceDetail?.order_dynamic_data
                                .valuation
                            }
                            style={{ width: '100%' }}
                            className={'kinput'}
                            formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}

                          />
                        </Form.Item>
                      </Col>
                      <Col xs={12} md={4}>
                        <Form.Item
                          className="mt-auto"
                          name="diff"
                          label="Diferencia"
                        /* label={intl.formatMessage({
                        id: "Total",
                      })} */
                        >
                          <InputNumber
                            disabled={
                              !serviceOrder.serviceDetail?.order_dynamic_data
                                .valuation
                            }
                            style={{ width: '100%' }}
                            className={'kinput'}
                            formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}

                          />
                          {/*<Input
                            readOnly
                            className={"kinput"}
                            disabled={
                              !serviceOrder.serviceDetail?.order_dynamic_data
                                  .valuation
                            }
                        />*/}
                        </Form.Item>
                      </Col>
                      <Col xs={12} md={4}>
                        <Form.Item
                          className="mt-auto"
                          name="status"
                          label={intl.formatMessage({
                            id: "status",
                          })}
                          rules={[ruleRequired]}
                        >
                          <KSelect
                            options={estatusList}
                            style={{ minWidth: 150, width: "100% " }}
                            disabled={
                              !serviceOrder.serviceDetail?.order_dynamic_data
                                .valuation
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={12} md={4}>
                        <Form.Item
                          className="mt-auto"
                          name="priority"
                          label={'Prioridad'}

                        >
                          <KSelect
                            options={priorityList}
                            style={{ minWidth: 150, width: "100% " }}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={12} md={4}>
                        <Form.Item
                          className="mt-auto"
                          name="supplier_category"
                          label={'Tipo de proveedor'}
                        >
                          <KSelect
                            allowClear
                            options={suppCatList}
                            style={{ minWidth: 150, width: "100% " }}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={24}>
                        <Space>

                          <KButton
                            type={"primary"}
                            text={
                              <FormattedMessage id="services.createNew.addNewShort" />
                            }
                            htmlType="submit"
                            disabled={
                              !serviceOrder.serviceDetail?.order_dynamic_data
                                .valuation
                            }
                            loading={savingBudget}
                            style={{ marginTop: "auto" }}
                          />
                          <KButton size={'large'} type={'secondary'}
                            text={'Cambiar Status masivo'} onClick={() => setOpenModalStatus(true)} disabled={rowSelected.length === 0} />

                          <KButton size={'large'} type={'error'}
                            text={'Eliminar masivo'} onClick={() => setOpenModalDelete(true)} disabled={rowSelected.length === 0} />

                        </Space>

                      </Col>
                    </Row>
                  </Form>
                }

                <Row style={{ marginTop: 20 }}>
                  <Col span={24}>
                    <Form
                      onFinish={updateBudget}
                      form={formEditBudget}
                      onChange={getTotalEdit}
                    >
                      <CustomTable
                        fixed={true}
                        scroll={{ x: 1500 }}
                        loading={loading} dataSource={valuation?.budget} columns={columns} footer={() => <FooterItems />} />
                    </Form>
                  </Col>
                </Row>
              </Spin>
            </div>
          </Card>
        </Layout>
        <KModal title="Reportes" loading={loadingReport} visible={showReports} onOk={() => setShowReports(false)} onCancel={() => setShowReports(false)}>
          <Row gutter={16}>
            <Col span={24}>
              <KButton
                onClick={reportBudgetComplements}
                type={"outline"}
                style={{ width: '100%', marginTop: 10 }}
                text={"Descargar reporte de complementos"}
              />
            </Col>
            <Col span={24}>
              <KButton
                onClick={reportBudgetParts}
                type={"outline"}
                style={{ width: '100%', marginTop: 10 }}
                text={"Descargar reporte de refacciones"}
              />
            </Col>
            <Col span={24}>
              <KButton
                onClick={reportBudgetCosts}
                type={"outline"}
                style={{ width: '100%', marginTop: 10 }}
                text={"Descargar reporte de costos"}
              />
            </Col>
            <Col span={24}>
              <KButton
                onClick={reportPrice}
                type={"outline"}
                style={{ width: '100%', marginTop: 10 }}
                text={"Descargar cotización"}
              />
            </Col>
            <Col span={24}>
              <KButton
                onClick={reportPriceIVA}
                type={"outline"}
                style={{ width: '100%', marginTop: 10 }}
                text={"Descargar cotización IVA"}
              />
            </Col>
            <Col span={24}>
              <KButton
                onClick={reportOrderWork}
                type={"outline"}
                style={{ width: '100%', marginTop: 10 }}
                text={"Descargar orden de trabajo"}
              />
            </Col>
          </Row>
        </KModal>
      </MainLayoutv2>
    </>
  );
};

const mapStateToProps = (state) => ({
  settings: state.settings,
  serviceOrder: state.serviceOrder,
  members: state.members,
});

export default injectIntl(
  connect(mapStateToProps, { getServiceDetail, getParts, getSupplierAction })(ServiceValuation)
);